import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { FRONTEND_BASE_URL } from "../../config/config";

const MetaData = (props) => {
  let location = useLocation();
  let currentUrl = FRONTEND_BASE_URL + location.pathname;
  let quote = props.quote || "";

  let title =
    props.title || "Rashu - Stories and News from the Maldives Islands";

  let image = props.image || "";

  let description =
    props.description || "Stories and News from the Maldives Islands";

  let hashtag = props.hashtag !== undefined ? props.hashtag : "";

  return (
    <Helmet>
      <title>{title}</title>
      <meta charset="utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="csrf_token" content="" />
      <meta property="type" content="article" />
      <meta property="url" content={currentUrl} />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
      <meta name="theme-color" content="#ffffff" />
      <meta name="_token" content="" />
      <meta name="robots" content="noodp" />
      <meta property="title" content={title} />
      <meta property="quote" content={quote} />
      <meta name="description" content={description} />
      <meta property="image" content={image} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={title} />
      <meta property="og:quote" content={quote} />
      <meta property="og:hashtag" content={hashtag} />
      <meta property="og:image" content={image} />
      <meta property="og:image:type" content="image/*" />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:site_name" content="Rashu" />
      <meta property="og:description" content={description} />{" "}
      <meta name="twitter:card" content="summary_large_image" />
      <meta key="twitter:site" property="twitter:site" content={title} />
      <meta key="twitter:title" property="twitter:title" content={title} />
      <meta
        key="twitter:description"
        property="twitter:description"
        content={description}
      />
      <meta key="twitter:image" name="twitter:image" content={image} />
    </Helmet>
  );
};

export default MetaData;
