import React, { useEffect } from "react";

import "./Spinner.scss";

const Spinner = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="loading-spinner">
      <svg viewBox="25 25 50 50">
        <circle cx="50" cy="50" r="20"></circle>
      </svg>
    </div>
  );
};

export default Spinner;
