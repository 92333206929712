import React from "react";

import { ReactComponent as TwitterIcon } from "../../assets/images/twitter.svg";
import { ReactComponent as InstagramIcon } from "../../assets/images/instagram.svg";
import { ReactComponent as FacebookIcon } from "../../assets/images/facebook.svg";
import { ReactComponent as YoutubeIcon } from "../../assets/images/youtube.svg";
import { ReactComponent as TiktokIcon } from "../../assets/images/tiktok.svg";

import { ReactComponent as MailIcon } from "../../assets/images/mail.svg";
// import { ReactComponent as PhoneIcon } from "../../assets/images/phone.svg";
import { ReactComponent as MapIcon } from "../../assets/images/map.svg";

import { ReactComponent as Logo } from "../../assets/images/logo.svg";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="text-md-left">
        <div className="row">
          <Logo />
        </div>

        <div className="row">
          <div className="col-md-6 col-lg-3 about-us">
            <p style={{ color: "rgb(196, 196, 196)" }}>
              We bring you news, stories and opinions from across our tiny
              islands of the Maldives. Each island has a story to tell and each
              one of us have an experience that is worth sharing. These are
              stories that inspire us and this is a window that connects us all.
            </p>
          </div>

          <div className="col-md-6 col-lg-3 offset-lg-5 pl-md-5 footer-menu">
            <ul
              className="list-unstyled"
              style={{ color: "rgb(196, 196, 196)" }}
            >
              <li>CONTACT US</li>
              {/* <li>
                <PhoneIcon /> +960 777 7777
              </li> */}
              <li>
                <MailIcon /> info@rashu.mv
              </li>
              <li>
                <MapIcon /> G. Light Grey, Rah’dhebai Hingun, Male', Maldives
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row social-media">
        <div className="right-20">
          <a href="https://www.facebook.com/RashuPortal">
            <FacebookIcon />
          </a>
        </div>
        <div className="right-20">
          <TwitterIcon />
        </div>
        <div className="right-20">
          <a href="https://www.instagram.com/rashumv/">
            <InstagramIcon />
          </a>
        </div>
        <div className="right-20">
          <a href="https://www.youtube.com/channel/UCbYeff1bh-1kZMw2IiACbeg">
            <YoutubeIcon />
          </a>
        </div>
        <div className="right-20">
          <TiktokIcon />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
