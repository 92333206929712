import React from "react";
import ReactDOM from "react-dom";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { SWRConfig } from "swr";
import { fetcher, GA_TRACKING_ID } from "./config/config";
import GA4React from "ga-4-react";
import App from "./App";

import "bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ga4react = new GA4React(GA_TRACKING_ID);

TimeAgo.addDefaultLocale(en);

(async () => {
  await ga4react.initialize();
  ReactDOM.render(
    <React.StrictMode>
      <SWRConfig
        value={{
          fetcher: fetcher,
        }}
      >
        <App />
      </SWRConfig>
    </React.StrictMode>,
    document.getElementById("root")
  );
})();
