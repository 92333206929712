import React from "react";

const NotFound = ({ text }) => {
  return (
    <div
      className="col-12 d-flex align-items-center justify-content-center typewriter"
      style={{
        padding: "20vh 0",
      }}
    >
      <h2 className="text-center">{text}</h2>
    </div>
  );
};

export default NotFound;
