import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { ReactComponent as MenuIcon } from "../../assets/images/list.svg";
import { ReactComponent as SearchIcon } from "../../assets/images/search.svg";
import { ReactComponent as FlightIcon } from "../../assets/images/flight-icon.svg";
import { ReactComponent as WeatherIcon } from "../../assets/images/weather-icon.svg";

import "./Header.scss";

const CATEGORIES = require("../../lib/categories.json");

const Header = () => {
  const [dropdownHidden, setDropdownHidden] = useState(true);

  const toggleDropdown = () => {
    if (dropdownHidden) {
      setDropdownHidden(false);
    } else {
      setDropdownHidden(true);
    }
  };

  const hideDropdown = () => {
    setDropdownHidden(true);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        document.querySelector(".logo").classList.add("narrow");
      } else {
        document.querySelector(".logo").classList.remove("narrow");
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll, { passive: true });
    };
  }, []);

  return (
    <>
      <div className="top-nav" style={{ zIndex: "99", position: "relative" }}>
        <div className="top-nav-link">
          <Link to="/weather">
            <WeatherIcon />
            Weather
          </Link>{" "}
        </div>

        <div className="top-nav-link">
          <Link to="/flights">
            <FlightIcon height="15px" />
            Flights
          </Link>
        </div>

        <div className="top-nav-link end">
          <Link to="/search">
            <SearchIcon height="13px" />
            Search
          </Link>
        </div>
      </div>
      <div className="header">
        <div className="logo-container">
          <div
            className="menu-button menu-button-white"
            onClick={toggleDropdown}
          >
            <MenuIcon />
          </div>

          <Link to="/">
            <Logo
              className="logo"
              onClick={() =>
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                })
              }
            />
          </Link>
        </div>

        <div className="navbar-links desktop-nav">
          <div
            className="menu-button menu-button-black"
            onClick={toggleDropdown}
          >
            <MenuIcon />
          </div>

          {CATEGORIES.slice(0, 7).map(({ id, in_dhivehi }) => (
            <div key={id} className="navbar-link" onClick={hideDropdown}>
              <Link to={`/category/${id}`}>{in_dhivehi}</Link>
            </div>
          ))}
        </div>

        <div
          className="navbar-links navbar-dropdown desktop-nav"
          style={{
            zIndex: "-1",
            position: "absolute",
            width: "100%",
            transform: dropdownHidden ? "translateY(-100%)" : "",
          }}
        >
          {CATEGORIES.slice(7).map(({ id, in_dhivehi }) => (
            <div key={id} className="navbar-link" onClick={hideDropdown}>
              <Link to={`/category/${id}`}>{in_dhivehi}</Link>
            </div>
          ))}
          <div className="navbar-link" onClick={hideDropdown}>
            <Link to={`/blogs`}>ރަށު ފީތާ</Link>
          </div>
          <div className="navbar-link" onClick={hideDropdown}>
            <Link to={`/filter`}>އަތޮޅު ތެރެއިން</Link>
          </div>
        </div>

        <div
          className="navbar-links navbar-dropdown mobile-nav"
          style={{
            zIndex: "-1",
            position: "absolute",
            width: "100%",
            transform: dropdownHidden ? "translateY(-100%)" : "",
          }}
        >
          {CATEGORIES.slice(0, 3).map(({ id, in_dhivehi }) => (
            <div key={id} className="navbar-link" onClick={hideDropdown}>
              <Link to={`/category/${id}`}>{in_dhivehi}</Link>
            </div>
          ))}
        </div>
        <div
          className="navbar-links navbar-dropdown mobile-nav"
          style={{
            zIndex: "-1",
            position: "absolute",
            width: "100%",
            transform: dropdownHidden
              ? "translateY(-100%)"
              : "translateY(100%)",
          }}
        >
          {CATEGORIES.slice(3, 7).map(({ id, in_dhivehi }) => (
            <div key={id} className="navbar-link" onClick={hideDropdown}>
              <Link to={`/category/${id}`}>{in_dhivehi}</Link>
            </div>
          ))}
        </div>
        <div
          className="navbar-links navbar-dropdown mobile-nav"
          style={{
            zIndex: "-1",
            position: "absolute",
            width: "100%",
            transform: dropdownHidden
              ? "translateY(-100%)"
              : "translateY(200%)",
          }}
        >
          {CATEGORIES.slice(7, 11).map(({ id, in_dhivehi }) => (
            <div key={id} className="navbar-link" onClick={hideDropdown}>
              <Link to={`/category/${id}`}>{in_dhivehi}</Link>
            </div>
          ))}
        </div>
        <div
          className="navbar-links navbar-dropdown mobile-nav"
          style={{
            zIndex: "-1",
            position: "absolute",
            width: "100%",
            transform: dropdownHidden
              ? "translateY(-100%)"
              : "translateY(300%)",
          }}
        >
          {CATEGORIES.slice(11).map(({ id, in_dhivehi }) => (
            <div key={id} className="navbar-link" onClick={hideDropdown}>
              <Link to={`/category/${id}`}>{in_dhivehi}</Link>
            </div>
          ))}
          <div className="navbar-link" onClick={hideDropdown}>
            <Link to={`/blogs`}>ރަށު ފީތާ</Link>
          </div>
          <div className="navbar-link" onClick={hideDropdown}>
            <Link to={`/filter`}>އަތޮޅު ތެރެއިން</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
