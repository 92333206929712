import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import MetaData from "./components/MetaData/MetaData";
import Spinner from "./components/Spinner/Spinner";
import NotFound from "./components/NotFound/NotFound";
import Header from "./components/Layout/Header";
import Footer from "./components/Layout/Footer";

import "./styles/main.scss";

const HomePage = lazy(() => import("./pages/HomePage/HomePage"));
const ArticlePage = lazy(() => import("./pages/ArticlePage/ArticlePage"));
const ArticlePreviewPage = lazy(() =>
  import("./pages/ArticlePage/ArticlePreviewPage")
);
const BlogPageContainer = lazy(() =>
  import("./pages/BlogPage/BlogPageContainer")
);
const BlogPreviewPage = lazy(() => import("./pages/BlogPage/BlogPreviewPage"));
const CategoryPageContainer = lazy(() =>
  import("./pages/CategoryPage/CategoryPageContainer")
);
const BlogsPage = lazy(() => import("./pages/CategoryPage/BlogsPage"));
const PrivacyPolicyPage = lazy(() =>
  import("./pages/PrivacyPolicyPage/PrivacyPolicyPage")
);
const FilterPage = lazy(() => import("./pages/FilterPage/FilterPage"));
const SearchPage = lazy(() => import("./pages/SearchPage/SearchPage"));
const WeatherPage = lazy(() => import("./pages/WeatherPage/WeatherPage"));
const FlightsPage = lazy(() => import("./pages/FlightsPage/FlightsPage"));

function App() {
  return (
    <Router>
      <div className="App">
        <MetaData></MetaData>
        <Header />

        <Switch>
          <Suspense fallback={<Spinner />}>
            <Route path="/" exact>
              <HomePage />
            </Route>

            <Route path="/article/:id">
              <ArticlePage />
            </Route>

            <Route path="/preview/:id">
              <ArticlePreviewPage />
            </Route>

            <Route path="/category/:id">
              <CategoryPageContainer />
            </Route>

            <Route path="/blogs">
              <BlogsPage />
            </Route>

            <Route path="/blog/:id">
              <BlogPageContainer />
            </Route>

            <Route path="/blog-preview/:id">
              <BlogPreviewPage />
            </Route>

            <Route path="/privacy-policy">
              <PrivacyPolicyPage />
            </Route>

            <Route path="/filter/:atoll?">
              <FilterPage />
            </Route>

            <Route path="/search">
              <SearchPage />
            </Route>

            <Route path="/weather">
              <WeatherPage />
            </Route>

            <Route path="/flights">
              <FlightsPage />
            </Route>
          </Suspense>

          <Route>
            <NotFound text="ތިޔަ ހޯއްދަވާ ސަފްހާއެއް ނުފެނުނު" />
          </Route>
        </Switch>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
